<script>
  import { f7, Link, Input, Button, AccordionContent, Block, List, ListItem, ListInput, ListButton, Searchbar } from 'framework7-svelte';

  import BookListView from './BookListView.svelte';
  import { Circle2 } from 'svelte-loading-spinners';
  import FileUpload from 'sveltefileuploadcomponent';
  import { sendFile } from './Authenticate.svelte';
  import Dropzone from 'svelte-file-dropzone';
  import { createEventDispatcher } from 'svelte';
  import NachTag from './NachTag.svelte';
  import NachAuthor from './NachAuthor.svelte';
  import EinstiegsTipps from './EinstiegsTipps.svelte';

  const dispatch = createEventDispatcher();

  export let books_json;
  export let id_to_title_dict;
  export let media_url;
  export let books_ready;
  export let possible_tags_json = [];

  let modal_type_select = 'einsteiger';
  let hidden = 'hidden';
  let hidden2 = 'hidden';
  let hidden3 = false;

  let files = {
    accepted: [],
    rejected: [],
  };

  function tabShow() {
    console.log('switched tab alle docs');
  }

  async function gotFile(files) {
    let f = files.detail.files;
    var data = new FormData();

    //do something with files
    console.log('Got files!');
    for (let i = 0; i < f.length; i++) {
      console.log(f);
      console.log('filename:');
      console.log(f[i].name);
      data.append('files', f[i], f[i].name);
    }
    console.log('FormData:');
    console.log(data);
    await sendFile(data);
  }

  async function handleFilesSelect(e) {
    console.log('handleFilesSelect');
    console.log(e);
    const { acceptedFiles, fileRejections } = e.detail;
    files.accepted = [...files.accepted, ...acceptedFiles];
    files.rejected = [...files.rejected, ...fileRejections];

    console.log(files.accepted);
    const formData = new FormData();
    formData.append('dataFile', files.accepted[0]);
    let ok = await sendFile(formData);
    files = {
      accepted: [],
      rejected: [],
    };
    if (ok) {
      dispatch('reload', {
        text: 'reload!',
      });
      alert('Upload Erfolgreich!');
    } else {
      alert('Upload Fehlgeschlagen!');
    }
  }

  function aktualisieren(e) {
    dispatch('reload', {
      text: 'reload!',
    });
  }

  function clickTab(modal_type_select) {
    console.log(modal_type_select);
    hidden3 = true;
    console.log('hidden 3 false');

    if (modal_type_select === 'kategorie') {
      console.log('set SHOW');
      hidden = 'none';
    } else {
      console.log('set HIDDEN');
      hidden = 'hidden';
    }

    if (modal_type_select === 'Nach AutorIn') {
      console.log('set SHOW');
      hidden2 = 'none';
    } else {
      console.log('set HIDDEN');
      hidden2 = 'hidden';
    }

    if (modal_type_select === 'einsteiger') {
      hidden = 'hidden';
      hidden2 = 'hidden';
      hidden3 = false;
      console.log('hidden3 true');
    }
  }

</script>

<style>
  .dragging .fileupload {
    color: red;
    height: 200px;
  }

  .fileupload {
    height: 100px;
    border-style: dotted;
  }

  .side {
    background-color: grey;
  }

</style>

<div class="card">
  <div class="card-body">Achtung: Die Zuordnung zu Kategorien hat noch nicht stattgefunden!</div>
</div>

<div class="card">
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <label
        on:click={clickTab(modal_type_select)}
        class="btn"
        class:btn-primary={modal_type_select === 'alle'}
        class:btn-default={modal_type_select != 'alle'}>
        <input type="radio" name="type" id="type_note" autocomplete="off" on:click={() => (modal_type_select = 'alle')} />
        Alle Werke
      </label>
      <label
        on:click={clickTab(modal_type_select)}
        class="btn"
        class:btn-primary={modal_type_select === 'kategorie'}
        class:btn-default={modal_type_select != 'kategorie'}>
        <input type="radio" name="type" id="type_note" autocomplete="off" on:click={() => (modal_type_select = 'kategorie')} />
        Nach Kategorie
      </label>
      <label
        on:click={clickTab(modal_type_select)}
        class="btn"
        class:btn-primary={modal_type_select === 'einsteiger'}
        class:btn-default={modal_type_select != 'einsteiger'}>
        <input type="radio" name="type" id="type_note" autocomplete="off" on:click={() => (modal_type_select = 'einsteiger')} />
        Einstiegs Tipps
      </label>
      <label
        on:click={clickTab(modal_type_select)}
        class="btn"
        class:btn-primary={modal_type_select === 'Nach AutorIn'}
        class:btn-default={modal_type_select != 'Nach AutorIn'}>
        <input type="radio" name="type" id="type_note" autocomplete="off" on:click={() => (modal_type_select = 'Nach AutorIn')} />
        Nach AutorIn
      </label>
      <label
        on:click={clickTab(modal_type_select)}
        class="btn"
        class:btn-primary={modal_type_select === 'hochladen'}
        class:btn-default={modal_type_select != 'hochladen'}>
        <input type="radio" name="type" id="type_note" autocomplete="off" on:click={() => (modal_type_select = 'hochladen')} />
        File Hochladen
      </label>
    </div>
  </div>
</div>

<p />
<p />

{#if modal_type_select === 'alle'}
  <p />

  {#if books_ready}
    <Searchbar searchContainer=".search-list" searchIn=".item-title" placeholder="Nach Titel suchen" />

    <Button on:click={aktualisieren}>aktualisieren</Button>

    <p />
    <p />
    <Block />

    <List class="searchbar-not-found">
      <ListItem title="Nothing found" />
    </List>

    <List class="search-list searchbar-found">
      {#each books_json as res}
        {#if res}
          <ListItem target="_blank" external href="{media_url}{res.name}" title={res.name} link="{media_url}{res.name}">
            <BookListView {...res} />
            <!-- <Link href="http://google.com" external>{res.name}</Link> -->
          </ListItem>
        {/if}
      {/each}
    </List>
  {:else}
    <Circle2 />
  {/if}
{/if}

{#if modal_type_select === 'hochladen'}
  <p>Achtung: Upload Funktioniert zur Zeit nur mit einzelnen Files!</p>

  <Dropzone on:drop={handleFilesSelect} />
  <ol>
    {#each files.accepted as item}
      <li>{item.name}</li>
    {/each}
  </ol>
{/if}

<NachAuthor {possible_tags_json} {books_json} {media_url} hidden={hidden2} />
<EinstiegsTipps hidden={hidden3} />

<!-- {#if modal_type_select === 'kategorie'} -->
<NachTag {possible_tags_json} {books_json} {media_url} {hidden} />
<!-- {/if} -->
