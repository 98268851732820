<script>
  import { f7, Badge, Col, Row } from 'framework7-svelte';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let possible_tags = [];
  export let chosen_tags = [];
  export let data = {};

  function has_obj(array, obj) {
    for (let i = 0; i < array.length; i++) {
      if (obj.id == array[i].id) return true;
    }
    return false;
  }

  function add_to_chosen(e) {
    console.log('Add to Chosen');
    console.log(e);
    chosen_tags.push(e);
    chosen_tags = chosen_tags;
    data['edited'] = true;

    dispatch('tagchosen', {
      text: 'Tag has changed!',
    });
  }

  function remove_from_chosen(e) {
    console.log('Remove from Chosen');
    console.log(e);
    for (var i = 0; i < chosen_tags.length; i++) {
      if (chosen_tags[i] === e) {
        chosen_tags.splice(i, 1);
        chosen_tags = chosen_tags;
      }
    }
    console.log('HALLOO');
    chosen_tags = chosen_tags;
    console.log(chosen_tags);
    data.tags = chosen_tags;
    console.log(data);
    data = data;
  }

</script>

<Col>
  {#each chosen_tags as tag_in}
    <Row>
      <a on:click={remove_from_chosen(tag_in)}><Badge color="green">{tag_in.name}</Badge></a>
    </Row>
  {/each}

  {#each possible_tags as tag_pos}
    <Row>
      {#if !has_obj(chosen_tags, tag_pos)}
        <a on:click={add_to_chosen(tag_pos)}><Badge color="red">{tag_pos.name}</Badge></a>
      {/if}
    </Row>
  {/each}
</Col>
