<script>
  import {
    f7,
    Chip,
    Block,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Link,
    Icon,
    Input,
    Button,
    List,
    Badge,
    ListItem,
    ListInput,
    ListButton,
    Searchbar,
    Col,
    Toggle,
    Row,
    AccordionContent,
    Popup,
    Page,
    Navbar,
    NavRight,
  } from 'framework7-svelte';

  import BookListView from './BookListView.svelte';

  import { MDBCardGroup, MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol } from 'mdbsvelte';

  let indices = [
    [0, 4],
    [4, 8],
    [8, 12],
    [12, 16],
    [16, 20],
    [20, 24],
  ];

  export let possible_tags_json = [];
  export let hidden = 'none';
  export let books_json;
  export let media_url;

  let current_tag = {
    id: 0,
    name: 'hallo',
  };

  let popup;
  let tag_list = [];

  function clicked(tag) {
    console.log('clicked');
    current_tag = tag;
    console.log(current_tag);
    popup.instance().open();
  }

</script>

<style>
  .hidden {
    visibility: hidden;
    display: none;
  }
  div {
    cursor: pointer;
  }

</style>

<div class:hidden={hidden === 'hidden'}>
  {#each indices as i}
    <MDBCardGroup>
      {#each possible_tags_json as tag, index}
        {#if index >= i[0] && index < i[1]}
          <MDBCard class="abc" on:click={clicked(tag)}>
            <MDBCardImage
              src="https://mdbootstrap.com/img/Photos/Others/images/{(index + 80).toString()}.jpg"
              alt="MDBCard image cap"
              top
              hover
              overlay="white-slight" />
            <MDBCardBody>
              <div>
                <MDBCardTitle tag="h5">{tag.name}</MDBCardTitle>
                <MDBCardText>
                  <!-- {tag.description} -->
                </MDBCardText>
              </div>
            </MDBCardBody>
          </MDBCard>
        {/if}
      {/each}
    </MDBCardGroup>
  {/each}
</div>

<Popup bind:this={popup} class="demo-popup-swipe" swipeToClose>
  <Page>
    <Navbar title={current_tag.name}>
      <Searchbar searchContainer=".search-list" searchIn=".item-title" />

      <List class="searchbar-not-found">
        <ListItem title="Nothing found" />
      </List>

      <NavRight>
        <Link popupClose>Close</Link>
      </NavRight>
    </Navbar>

    <div style="height: 100%" class="display-flex justify-content-center align-items-center">
      <List class="search-list searchbar-found">
        {#each books_json as book}
          {#each book.tags as tag}
            {#if tag.name === current_tag.name}
              <ListItem title={book.name} link="{media_url}{book.name}" />
            {/if}
          {/each}
        {/each}
      </List>
    </div>
  </Page>
</Popup>
