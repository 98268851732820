<script>
  import { Page, Navbar, Block, List, ListItem } from 'framework7-svelte';

  export let f7route;

  const user = f7route.context.user;

</script>

<Page>
  <Navbar title={`${user.firstName} ${user.lastName}`} backLink="Back" />
  <Block strong>{user.about}</Block>
  <List>
    {#each user.links as link}
      <ListItem link={link.url} title={link.title} external target="_blank" />
    {/each}
  </List>
</Page>
