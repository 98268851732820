<script>
  import {
    f7,
    Chip,
    Block,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Link,
    Icon,
    Input,
    Button,
    List,
    Badge,
    ListItem,
    ListInput,
    ListButton,
    Searchbar,
    Col,
    Toggle,
    Row,
    AccordionContent,
    Popup,
    Page,
    Navbar,
    NavRight,
  } from 'framework7-svelte';
  import { MDBCardGroup, MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol } from 'mdbsvelte';

  export let books_json;
  export let media_url;
  export let hidden = 'none';

  let popup;

  let indices = [
    [0, 4],
    [4, 8],
    [8, 12],
    [12, 16],
    [16, 20],
    [20, 24],
  ];

  let current_author = {
    id: 0,
    name: 'hallo',
  };

  let authors = [];
  let werke = [];
  let authors_ready = false;

  $: {
    let blah = books_json;

    for (let i = 0; i < books_json.length; i++) {
      if (!authors.includes(books_json[i].author)) {
        authors.push(books_json[i].author);
      }
    }
    authors = authors;
    authors_ready = true;
  }

  function clicked(a) {
    console.log('clicked author');

    let book_list = [];
    for (let i = 0; i < books_json.length; i++) {
      if (books_json[i].author.includes(a)) {
        book_list.push(books_json[i]);
      }
    }
    werke = book_list;
    popup.instance().open();
  }

</script>

<style>
  .hidden {
    visibility: hidden;
    display: none;
  }
  div {
    cursor: pointer;
  }

</style>

{#if authors_ready}
  <div class:hidden={hidden === 'hidden'}>
    {#each indices as i}
      <MDBCardGroup>
        {#each authors as author, index}
          {#if index >= i[0] && index < i[1]}
            <MDBCard class="abc" on:click={clicked(author)}>
              <MDBCardImage
                src="https://mdbootstrap.com/img/Photos/Others/images/{(index + 80).toString()}.jpg"
                alt="MDBCard image cap"
                top
                hover
                overlay="white-slight" />
              <MDBCardBody>
                <div>
                  <MDBCardTitle tag="h5">{author}</MDBCardTitle>
                  <MDBCardText>
                    <!-- {tag.description} -->
                  </MDBCardText>
                </div>
              </MDBCardBody>
            </MDBCard>
          {/if}
        {/each}
      </MDBCardGroup>
    {/each}
  </div>

  <Popup bind:this={popup} class="demo-popup-swipe" swipeToClose>
    <Page>
      <Navbar title={current_author.name}>
        <Searchbar searchContainer=".search-list" searchIn=".item-title" />

        <List class="searchbar-not-found">
          <ListItem title="Nothing found" />
        </List>

        <NavRight>
          <Link popupClose>Close</Link>
        </NavRight>
      </Navbar>

      <div style="height: 100%" class="display-flex justify-content-center align-items-center">
        <List class="search-list searchbar-found">
          {#each werke as book}
            <ListItem title={book.name} link="{media_url}{book.name}" />
          {/each}
        </List>
      </div>
    </Page>
  </Popup>
{/if}
