<script>
  import {
    f7,
    Chip,
    Block,
    Checkbox,
    Link,
    Panel,
    View,
    Page,
    Icon,
    Input,
    Button,
    List,
    Badge,
    ListItem,
    ListInput,
    ListButton,
    Searchbar,
    Col,
    Toggle,
    Row,
    AccordionContent,
  } from 'framework7-svelte';

  import TagChooser from './TagChooser.svelte';
  import BookListViewEdit from './BookListViewEdit.svelte';
  import { Circle2 } from 'svelte-loading-spinners';
  import { createByUrl, createPatchByUrlWithThen } from './Authenticate.svelte';
  import { book_update_url } from './Site.svelte';

  export let media_url;
  export let books_json;
  export let books_ready;
  export let possible_tags_json;
  export let books_json_backup;

  let is_filter_author_enabled = false;
  let is_filter_title_enabled = false;

  export const ip_adress_prefix = 'https://kjoe.ml:8000/';
  const book_update_url_par = ip_adress_prefix + 'api_books/';

  let our_index = 0;

  function tabShow() {
    console.log('switched tab edit');
    let a = [{ name: 'hallo' }, { name: 'aallo' }, { name: 'zallo' }];
    console.log(a.sort(compare_name_1));
    console.log(a.sort(compare_name_2));
  }

  function add_tag() {
    let new_tag = { id: 3, name: 'NEU' };
    books_json[our_index].tags.push(new_tag);
    books_json[our_index].tags = books_json[index].tags;
  }

  function save() {
    books_json[our_index] = books_json[our_index];
    // rst call zum updaten
    let url = book_update_url_par + books_json[our_index].id.toString() + '/';
    let dict = books_json[our_index];

    createPatchByUrlWithThen(url, books_json[our_index]).then((response) =>
      response.json().then(function (data) {
        books_json[our_index]['edited'] = false;
      })
    );
    console.log('save');
  }

  function select_pdf(e, index) {
    console.log(e);
    console.log(index);
    our_index = index;
  }

  function compare_name_1(a, b) {
    console.log(a.name.localeCompare(b.name));
    return a.name.localeCompare(b.name);
  }

  function compare_name_2(a, b) {
    return !a.name.localeCompare(b.name);
  }

  function sort1() {
    console.log('sort1');
    books_json = books_json.sort(compare_name_1);
    books_json = books_json;
    console.log('sort1 finished');
  }

  function sort2() {
    console.log('sort2');
    books_json = books_json.sort(compare_name_2);
    books_json = books_json;
    console.log('sort2 finished');
  }

  function filter() {
    // is_filter_title_enabled
  }

  function reset_filter() {
    books_json = [];
    for (let i = 0; i < books_json_backup.length; i++) {
      books_json.push(books_json_backup[i]);
    }
  }

  function toggle_cb_author() {
    is_filter_author_enabled = !is_filter_author_enabled;

    console.log('is_filter_author_enabled', is_filter_author_enabled);
    console.log('is_filter_title_enabled', is_filter_title_enabled);

    //is_filter_author_enabled

    reset_filter();

    var l = books_json.length;

    for (let i = l - 1; i >= 0; i--) {
      var a = books_json[i].author;
      if ((a == null || a == '') && is_filter_author_enabled) {
        books_json.splice(i, 1);
        books_json[i] = books_json[i];
        console.log('1');
      }
      if (a != null && a != '' && !is_filter_author_enabled) {
        books_json.splice(i, 1);
        books_json[i] = books_json[i];
        console.log('2');
      }
    }
    books_json = books_json;
  }

  function toggle_cb_title() {
    is_filter_title_enabled = !is_filter_title_enabled;
    var l = books_json.length;

    for (let i = l - 1; i >= 0; i--) {
      var a = books_json[i].name_show;
      if ((a == null || a == '') && is_filter_title_enabled) {
        books_json.splice(i, 1);
        books_json[i] = books_json[i];
        console.log('3');
      }
      if (a != null && a != '' && !is_filter_title_enabled) {
        books_json.splice(i, 1);
        books_json[i] = books_json[i];
        console.log('4');
      }
    }

    books_json = books_json;
  }

</script>

<style>
  Row.selected {
    outline-color: lightblue;
    outline-style: dotted;
  }

  .Fixed {
    position: fixed;
    top: 30px;
  }

  .side {
    background-color: grey;
  }

  .my-color {
    background: #f7faff;
    margin-left: 10px;
    padding: 10px;
  }

  .my-color2 {
    background: #f7faff;
  }

  .inner {
    padding-bottom: 100px;
  }

  .smaller-list-item {
    height: 5px;
  }

  /* Limit resizable panel width */
  :global(.panel) {
    min-width: 100px;
    max-width: 90vw;
  }

</style>

{#if books_ready}
  <p />
  <Searchbar searchContainer=".search-list1" searchIn=".item-title" placeholder="Search" />
  <p />
  <Row>
    <Col>
      <Row>
        <Col>
          <List class="searchbar-not-found">
            <ListItem title="Nothing found" />
          </List>
          <List class="search-list1 searchbar-found">
            {#each books_json as res, index}
              <ListItem title={res.name} on:click={select_pdf(res.id, index)}><i slot="media" class="icon demo-list-icon" /></ListItem>

              <!-- href="{media_url}{res.name}" -->

              <!--  <Col>
                  {#if res.name_show == ''}
                    <Badge color="red">titel</Badge>
                  {:else}
                    <Badge color="green">titel</Badge>
                  {/if}

                  {#if res.author == null || res.author == ''}
                    <Badge color="red">author</Badge>
                  {:else}
                    <Badge color="green">author</Badge>
                  {/if}
                </Col>

                <Col>
                  {#if res.tags.length == 0}
                    <Badge color="red">tags</Badge>
                  {:else}
                    {#each res.tags as tag}
                      <Badge color="green">{tag.name}</Badge>
                    {/each}
                  {/if}
                </Col> -->

              <!-- 
      <BookListViewEdit {...res} />
 -->
            {/each}
          </List>
        </Col>
        <Col>
          <Col>
            <div class="Fixed my-color">
              <Block>
                <div class="inner">
                  <List noHairlinesMd>
                    <h4>{books_json[our_index].name}</h4>

                    <ListInput
                      type="text"
                      placeholder="Title"
                      info="Full title please"
                      bind:value={books_json[our_index].name_show}
                      onInput={(e) => {
                        books_json[our_index].name_show = e.target.value;
                        books_json[our_index]['edited'] = true;
                      }}
                      clearButton />

                    <ListInput
                      type="text"
                      placeholder="Autor"
                      info="Authors name please"
                      bind:value={books_json[our_index].author}
                      onInput={(e) => {
                        books_json[our_index].author = e.target.value;
                        books_json[our_index]['edited'] = true;
                      }}
                      clearButton />

                    <TagChooser
                      bind:data={books_json[our_index]}
                      on:tagchosen={save}
                      possible_tags={possible_tags_json}
                      chosen_tags={books_json[our_index].tags} />
                    <p />
                    <button class="button button-outline button-raised" on:click={save}> Save </button>
                    <Link external href="{media_url}{books_json[our_index].name}" class="button button-outline button-raised">Open</Link>
                  </List>
                </div>
              </Block>
            </div>
          </Col>
        </Col>
      </Row>
    </Col>
  </Row>
{:else}
  <Circle2 />
{/if}

<!-- <div class="col-2">
          <div class="my-color2">
            <List>
              <ListItem>
                {#if !is_filter_author_enabled}<span>Nur OHNE erfasstem Autor</span>{:else}<span>Nur MIT erfasstem Autor</span>{/if}
                <Toggle on:change={toggle_cb_author} bind:checked={is_filter_author_enabled} />
              </ListItem>

              <ListItem>
                {#if !is_filter_title_enabled}<span>Nur OHNE erfasstem Titel</span>{:else}<span>Nur MIT erfasstem Titel</span>{/if}
                <Toggle on:change={toggle_cb_title} bind:checked={is_filter_title_enabled} titel="hallo" />
              </ListItem>

              <ListItem accordionItem checkbox title="Nach Tags filtern (funktioniert noch nicht)">
                <AccordionContent>
                  <List>
                    {#each possible_tags_json as tag}
                      <ListItem class="smaller-list-item" checkbox title={tag.name} name="demo-checkbox" value={tag.name} />
                    {/each}
                  </List>
                </AccordionContent>
              </ListItem>
              <ListButton on:click={reset_filter} title="Alle anzeigen" />
            </List>
          </div>
        </div> -->
