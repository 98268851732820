<script>
  import { onMount } from 'svelte';

  import Authenticate, { authenticate, getByUrlWithThen, getByUrlMediaServer } from './Authenticate.svelte';

  import Site from './Site.svelte';

  import {
    f7,
    f7ready,
    App,
    Panel,
    Views,
    View,
    Popup,
    Page,
    Navbar,
    Toolbar,
    NavRight,
    Link,
    Block,
    BlockTitle,
    LoginScreen,
    LoginScreenTitle,
    List,
    ListItem,
    ListInput,
    ListButton,
    BlockFooter,
  } from 'framework7-svelte';

  import { navigate } from 'svelte-routing';

  import routes from '../js/routes';

  export let f7router, f7route;

  // Framework7 Parameters
  let f7params = {
    name: 'Frontend', // App name
    theme: 'auto', // Automatic theme detection

    // App routes
    routes: routes,
    // Register service worker
    serviceWorker: {
      path: '/service-worker.js',
    },
  };
  // // Login screen demo data
  // let username = 'employee';
  // let password = 'sveltetestpw';
  let username = '';
  let password = '';

  let logged_in = false;

  const ip_adress_prefix = 'https://kjoe.ml:8000/';

  let get_registerURLrefresh_url = ip_adress_prefix + 'api/token/refresh/';
  let get_registerURL = ip_adress_prefix + 'api/token/';

  async function login() {
    await log_auth();
  }

  function build_options() {
    let options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    };
    return options;
  }

  async function log_auth() {
    let auth = authenticate(get_registerURL, build_options());
    auth.then(function (result) {
      if (result) {
        // console.log('Auth:');
        // console.log(f7);

        logged_in = true;
      }
    });
    await auth;
  }

</script>

<style>
</style>

<Page>
  {#if !logged_in}
    <LoginScreen opened id="my-login-screen">
      <View>
        <Page loginScreen>
          <LoginScreenTitle>Login</LoginScreenTitle>
          <List form>
            <ListInput
              type="text"
              name="username"
              placeholder="Your username"
              value={username}
              onInput={(e) => (username = e.target.value)} />
            <ListInput
              type="password"
              name="password"
              placeholder="Your password"
              value={password}
              onInput={(e) => (password = e.target.value)} />
          </List>
          <List>
            <ListButton color="deeppurple" title="Sign In" onClick={login} />
          </List>
          <BlockFooter>Zugang: ask your local Schulungsleitung.<br />Click "Sign In" to close Login Screen</BlockFooter>
        </Page>
      </View>
    </LoginScreen>
  {/if}
  {#if logged_in}
    <Site />
  {/if}
</Page>
