<script>
  import Authenticate, { authenticate, getByUrlWithThen, createByUrlWithThen, getByUrlMediaServer } from './Authenticate.svelte';
  import { onMount } from 'svelte';
  import { Circle2 } from 'svelte-loading-spinners';
  import AlleDocs from './AlleDocs.svelte';
  import AllDocsEdit from './AllDocsEdit.svelte';
  import BookListView from './BookListView.svelte';

  import {
    f7,
    f7ready,
    App,
    Panel,
    Views,
    Col,
    Row,
    Badge,
    View,
    Popup,
    Page,
    Navbar,
    NavRight,
    Link,
    Block,
    Input,
    Button,
    BlockTitle,
    LoginScreen,
    LoginScreenTitle,
    List,
    ListItem,
    ListInput,
    ListButton,
    BlockFooter,
    Subnavbar,
    Toolbar,
    Tab,
    PageContent,
    Searchbar,
  } from 'framework7-svelte';

  export let name;
  let logged_in = false;
  let search_online = false;
  let books_ready = false;
  let show_search_spinner = false;
  let books_json_backup = [];

  // export const ip_adress_prefix = 'https://kjoe.ml:8000/';
  export const ip_adress_prefix = 'https://kjoe.ml:8000/';
  // const ip_adress_prefix_media_local = 'https://kjoe.ml:8001/media/';
  // const ip_adress_working = 'https://kjoe.ml:8000/';

  const get_great_dict = ip_adress_prefix + 'get_great_dict';
  const search_url = ip_adress_prefix + 'search/';
  const search_huge_url = ip_adress_prefix + 'search_complete_ranked/';

  let media_url = ip_adress_prefix + 'media/';
  // let media_url_working = ip_adress_working + 'media/';

  // let get_registerURLrefresh_url = ip_adress_prefix + 'api/token/refresh/';
  // let get_registerURL = ip_adress_prefix + 'api/token/';
  // let get_great_dict = media_url_working + 'great_dict_th_10.txt';
  // let get_id_to_title_dict = ip_adress_prefix + 'get_id_to_title_dict/';

  const get_id_to_title_dict = ip_adress_prefix + 'get_id_to_title_dict';
  const get_all_books = ip_adress_prefix + 'api_books/';
  const get_all_tags = ip_adress_prefix + 'api_tags/';

  const book_update_url_par = ip_adress_prefix + 'api_books/';

  let great_dict = {};
  let great_dict_keys = [];
  let id_to_title_dict = {};
  let id_to_title_list = ['hallo', 'hallo2'];
  let search = 'fridays';
  let similar_words = [];
  let great_dict_recieved = false;

  let search_results = [];

  let books_json = [];

  let possible_tags_json = [];

  let count_results = 0;

  let component;

  let aehnliche_woerter = [];

  let stringSimilarity;

  let panel_right;

  onMount(async () => {
    stringSimilarity = require('string-similarity');
    get_data();

    // panel_right.open();
  });

  async function get_data() {
    getByUrlMediaServer(get_great_dict).then((response) =>
      response.json().then(function (data) {
        great_dict = JSON.parse(data);
        great_dict_keys = Object.keys(great_dict);
        great_dict_recieved = true;
        // console.log(great_dict);
      })
    );

    getByUrlMediaServer(get_all_books).then((response) =>
      response.json().then(function (data) {
        books_json = data;
        books_json_backup = [];

        // console.log('books_json: ', books_json);

        for (let i = 0; i < books_json.length; i++) {
          books_json_backup.push(books_json[i]);
        }
        books_ready = true;
      })
    );

    getByUrlMediaServer(get_all_tags).then((response) =>
      response.json().then(function (data) {
        possible_tags_json = data;
      })
    );

    getByUrlWithThen(get_id_to_title_dict).then((response) =>
      response.json().then(function (data) {
        id_to_title_dict = JSON.parse(data);
        // console.log('id_to_title_dict Recieved!');
        for (var key in id_to_title_dict) {
          // check if the property/key is defined in the object itself, not in parent
          if (id_to_title_dict.hasOwnProperty(key)) {
            // console.log(key, id_to_title_dict[key]);
            id_to_title_list.push(id_to_title_dict[key]);
          }
        }
        id_to_title_list = id_to_title_list;
      })
    );
  }

  function tabShow() {
    // console.log('switched tab');
  }

  async function search_go_django() {
    show_search_spinner = true;
    let search_django = search.toLowerCase();
    let opt = { dict: search_django };

    createByUrlWithThen(search_url, opt).then((response) =>
      response.json().then(function (data) {
        let found = JSON.parse(data);
        search_results = found;
        search_online = true;
        show_search_spinner = false;
        // console.log(search_results);
        var matching_titles = searchMatchingTitlesOnline(search.toLowerCase(), id_to_title_dict);
        // console.log(matching_titles);
        search_results = matching_titles.concat(search_results);
        // console.log(search_results);
      })
    );
    check_similar_words(search_django);
  }

  async function check_similar_words(search_text) {
    similar_words = [];
    for (let i = 0; i < great_dict_keys.length; i++) {
      var similarity = stringSimilarity.compareTwoStrings(search_text, great_dict_keys[i]);
      if (similarity > 0.7) {
        similar_words.push(great_dict_keys[i]);
      }
    }
  }

  async function search_go_huge_django() {
    show_search_spinner = true;
    let search_django = search.toLowerCase();
    let opt = { dict: search_django };

    createByUrlWithThen(search_huge_url, opt).then((response) =>
      response.json().then(function (data) {
        let found = JSON.parse(data);
        search_results = found;
        search_online = true;
        show_search_spinner = false;
        var matching_titles = searchMatchingTitlesOnline(search.toLowerCase(), id_to_title_dict);

        search_results = matching_titles.concat(search_results);
      })
    );
    check_similar_words(search_django);
  }

  async function search_go() {
    show_search_spinner = true;
    let found = false;
    let ids = [];
    const words = search.toLowerCase().split(' ');

    for (let i = 0; i < words.length; i++) {
      if (great_dict.hasOwnProperty(words[i])) {
        let references = great_dict[words[i]];
        ids = ids.concat(references);
        found = true;
        search_results = ids.sort();
        search_online = false;
      }
    }
    if (found == false) {
      search_results = [];
      search_online = false;
    }

    var matching_titles = searchMatchingTitles(search.toLowerCase(), id_to_title_dict);

    search_results = matching_titles.concat(search_results);

    show_search_spinner = false;

    check_similar_words(search.toLowerCase());
  }

  async function search_for_similar_words(word) {
    // for (let i=0; i<10; i++){
    // 	console.log("Ähnlichkeit für Wort " + great_dict_keys[i]);
    // 	console.log(similarity(word, great_dict_keys[i]));
    // }
  }

  function similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength == 0) {
      return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
  }

  function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }

  function reload() {
    get_data();
  }

  function research(word) {
    search = word;
    search_go();
  }

  function searchMatchingTitles(word, id_title_dict) {
    let results = [];

    for (const [key, value] of Object.entries(id_title_dict)) {
      var title = value;
      if (title.toLowerCase().indexOf(word) != -1) {
        results.push(key);
        // console.log(word, title);
      }
    }

    return results;
  }

  function searchMatchingTitlesOnline(word, id_title_dict) {
    let results = [];

    for (const [key, value] of Object.entries(id_title_dict)) {
      var title = value;
      if (title.toLowerCase().indexOf(word) != -1) {
        results.push([key, 300]);
        // console.log(word, title);
      }
    }

    return results;
  }

  function is_search_possible() {
    if (!show_search_spinner) {
      if (great_dict_recieved) {
        return true;
      }
    }
    return false;
  }

</script>

<style>
  .btn-sm {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 9px;
  }

  .side {
    background-color: grey;
  }

  .my-color {
    background: yellow;
  }

</style>

<!-- <Navbar title="Marxistische Unterlagen" /> -->

<Toolbar class="toolbar tabbar toolbar-bottom" position="bottom">
  <div class="toolbar-inner">
    <a href="#tab-1" class="tab-link">
      <!-- Different icons for iOS and MD themes -->
      <i class="icon material-icons md-only">home</i>
      <span class="tabbar-label">Suche</span>
    </a>
    <a href="#tab-2" class="tab-link tab-link-active">
      <i class="icon material-icons md-only">list</i>
      <span class="tabbar-label">Alle</span>
    </a>
    <a href="#tab-3" class="tab-link"> <i class="icon material-icons md-only">edit</i> <span class="tabbar-label">Edit</span> </a>
    <a href="#tab-4" class="tab-link"> <i class="icon material-icons md-only">settings</i> <span class="tabbar-label">Settings</span> </a>
  </div>
</Toolbar>

<div class="tabs my-scolling-content ptr-watch-scrollable">
  <Tab on:tabShow={tabShow} id="tab-1" class="view tab page-content safe-areas">
    <Col>
      <Row />
      <Row>
        <Col width="100">
          {#if !great_dict_recieved}
            <Circle2 />
          {/if}
          <Block>
            <Input
              onInput={(e) => (search = e.target.value)}
              label="Wörtersuche"
              type="text"
              placeholder="Stichwort eingeben und Suchmethode wählen..."
              clearButton />

            {#if !show_search_spinner && great_dict_recieved}
              <Button on:click={search_go} filled>search</Button>
              <Button on:click={search_go_django} filled>slow search (Nur ein Wort)</Button>
            {:else}
              <Button on:click={search_go} disabled filled>search</Button>
              <Button on:click={search_go_django} disabled filled>slow search (Nur ein Wort)</Button>
            {/if}
          </Block>

          <Block>
            {#if similar_words}
              {#each similar_words as word}
                <a on:click={research(word)} href={'#'}><Badge color="red">{word}</Badge></a>
              {/each}
            {/if}
          </Block>

          {#if Object.keys(search_results).length}
            <h3>Search Results ({search_results.length})</h3>
          {/if}

          {#if show_search_spinner}
            <Circle2 />
          {/if}

          <List simpleList>
            {#if search_online}
              {#each search_results as res}
                <ListItem>
                  <h5>{id_to_title_dict[res[0]]}</h5>
                  <a class="link external" href="{media_url}{id_to_title_dict[res[0]]}">open</a>
                </ListItem>
              {/each}
            {/if}

            {#if !search_online}
              {#each search_results as res}
                <ListItem>
                  <h5>{id_to_title_dict[res]}</h5>
                  <a class="link external" href="{media_url}{id_to_title_dict[res]}">open</a>
                </ListItem>
              {/each}
            {/if}
          </List>
        </Col>
      </Row>
    </Col>
  </Tab>
  <Tab on:tabShow={tabShow} id="tab-2" class="view tab page-content safe-areas tab-active">
    <AlleDocs on:reload={reload} {books_json} {books_ready} {id_to_title_dict} {media_url} {possible_tags_json} />
  </Tab>
  <Tab on:tabShow={tabShow} id="tab-3" class="view tab page-content safe-areas">
    <AllDocsEdit {possible_tags_json} {books_json} {books_ready} {books_json_backup} {media_url} />
  </Tab>
  <Tab on:tabShow={tabShow} id="tab-4" class="view tab page-content safe-areas">Macht nix</Tab>
</div>
