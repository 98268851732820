<script>
  import { onMount } from 'svelte';

  import Authenticate, { authenticate, getByUrlWithThen, getByUrlMediaServer } from './Authenticate.svelte';

  import Login from './Login.svelte';

  import {
    f7,
    f7ready,
    App,
    Panel,
    Views,
    View,
    Popup,
    Page,
    Navbar,
    Toolbar,
    NavRight,
    Link,
    Block,
    BlockTitle,
    LoginScreen,
    LoginScreenTitle,
    List,
    ListItem,
    ListInput,
    ListButton,
    BlockFooter,
  } from 'framework7-svelte';

  import { navigate } from 'svelte-routing';

  import routes from '../js/routes';

  export let f7router, f7route;

  // Framework7 Parameters
  let f7params = {
    name: 'Frontend', // App name
    theme: 'auto', // Automatic theme detection
    themeDark: true,

    // App routes
    routes: routes,
    // Register service worker
    serviceWorker: {
      path: '/service-worker.js',
    },
  };
  // // Login screen demo data

</script>

<App params={f7params}>
  <main>
    <Login />
  </main>
</App>
