<script context="module">
  import { writable } from 'svelte/store';

  let auth = '';
  export const access = writable(0);
  export const refresh = writable(0);
  let const_access;
  let const_refresh;

  // const ip_adress_prefix = 'https://kjoe.ml:8000/';
  const ip_adress_prefix = 'https://kjoe.ml:8000/';
  let get_registerURLrefresh_url = ip_adress_prefix + 'api/token/refresh/';
  let get_file_upload_url = ip_adress_prefix + 'api_upload/';

  const unsubscribe_access = access.subscribe((value) => {
    const_access = value;
  });

  const unsubscribe_refresh = refresh.subscribe((value) => {
    const_refresh = value;
  });

  export async function createByUrl(url, dict) {
    var opt = buildOptionsWithDict(dict);
    var response = await fetch(url, opt);
    if (!response.ok) {
      refresh_token();
      return 0;
    }
    return response;
  }

  export async function getByUrl(url) {
    if (access == '') {
      return 0;
    }
    let response = await fetch(url, buildOptions());
    if (!response.ok) {
      refresh_token();
      return 0;
    }
    let json_data = await response.json();
    return json_data;
  }

  export function getByUrlWithThen(url) {
    if (access == '') {
      return 0;
    }
    return fetch(url, buildOptions()).catch(function () {
      // console.log('Try to refresh token');
      refresh_token();
      return 0;
    });
  }

  export async function getByUrlMediaServer(url) {
    let media_opts = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    };

    return fetch(url, media_opts);
  }

  export function createByUrlWithThen(url, dict) {
    var opt = buildOptionsWithDict(dict);
    var response = fetch(url, opt);
    response.catch((error) => {
      refresh_token();
    });
    return response;
  }

  export async function sendFile(file) {
    var opt = buildSendFileOptionsWithDict(file);
    var response = await fetch(get_file_upload_url, opt);
    if (!response.ok) {
      refresh_token();
      return 0;
    }
    return response;
  }

  function buildSendFileOptionsWithDict(file) {
    let opt = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + const_access,
        accept: 'application/json',
      },
      mode: 'cors',
      body: file,
      timeout: 50000,
    };
    // 'X-CSRF-TOKEN': getCookie('CSRF-TOKEN'),
    return opt;
  }

  export function createPatchByUrlWithThen(url, dict) {
    var opt = buildPatchOptionsWithDict(dict);
    var response = fetch(url, opt);
    response.catch((error) => {
      refresh_token();
    });
    return response;
  }

  export async function authenticate(register_url, options) {
    const response = await fetch(register_url, options);
    if (!response.ok) {
      refresh_token();
      return 0;
    }
    // console.log('Try Authenticate...');
    let data = await response.json();
    // console.log(data);
    if (data['access']) {
      access.set(data['access']);
      refresh.set(data['refresh']);
      // console.log('Authenticated!');
      return true;
    } else {
      return false;
    }
  }

  export async function refresh_token() {
    // console.log('in refresh token function');
    let options_refresh = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: const_refresh }),
    };
    // console.log(options_refresh);
    const response = await fetch(get_registerURLrefresh_url, options_refresh);
    let data = await response.json();
    access.set(data['access']);
    // refresh.set(data["refresh"]);
    // Anscheinend bekommt man beim refreshen keinen neuen refresh Token
    // console.log(access);
    return true;
  }

  function getCookie(name) {
    if (!document.cookie) {
      return null;
    }

    const xsrfCookies = document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((c) => c.startsWith(name + '='));

    if (xsrfCookies.length === 0) {
      return null;
    }
    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
  }

  function buildOptions() {
    let opt = {
      headers: {
        Authorization: 'Bearer ' + const_access,
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    };
    // 'X-CSRF-TOKEN': getCookie('CSRF-TOKEN'),
    return opt;
  }

  function buildOptionsWithDict(dict) {
    let opt = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + const_access,
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(dict),
    };
    // 'X-CSRF-TOKEN': getCookie('CSRF-TOKEN'),
    return opt;
  }

  function buildPatchOptionsWithDict(dict) {
    let opt = {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + const_access,
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(dict),
    };
    // 'X-CSRF-TOKEN': getCookie('CSRF-TOKEN'),
    return opt;
  }

  function json2array(json) {
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function (key) {
      result.push(json[key]);
    });
    return result;
  }

  export async function get_appointments() {
    let tmp_appointments_json_data = getRequest();
    await tmp_appointments_json_data;
    return parseAppointmentsJsonData(tmp_appointments_json_data);
  }

  function parseAppointmentsJsonData(appointments_json_data_tmp) {
    for (let i = 0; i < appointments_json_data_tmp.length; i++) {
      let entry = appointments_json_data_tmp[i];
      var date_start = moment(entry.appointment_date_start).format('DD-MM-YYYY');
      // let date_start = Date.parse();
      let date_end = moment(entry.appointment_date_end).format('DD-MM-YYYY');
      let start_time = moment(entry.appointment_date_start).format('hh:mm');
      let end_time = moment(entry.appointment_date_end).format('hh:mm');

      appointments_json_data_tmp[i].appointment_date_start = date_start;
      appointments_json_data_tmp[i].appointment_date_end = date_end;
      appointments_json_data_tmp[i].appointment_date_start_time = start_time;
      appointments_json_data_tmp[i].appointment_date_end_time = end_time;
    }
    return appointments_json_data_tmp;
  }

  async function getRequest() {
    appointments_json_data = await getByUrl(get_appointments_url());
    return true;
  }

</script>
