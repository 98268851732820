<script>
  export let hidden = false;

</script>

{#if !hidden}
  <div class="row">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col mt-0">
              <h5 class="card-title">Schneller Einstieg?</h5>
            </div>

            <div class="col-auto">
              <div class="stat text-primary"><i class="align-middle" data-feather="truck" /></div>
            </div>
          </div>
          <div class="mb-0">
            <span class="text">Link zu unserem Selbstschulungsleitfaden</span>

            <a
              target="_blank"
              href="https://kjoe.ml:8000/media/Schulungsleitfaden0.3.pdf"
              onclick="window.open('https://kjoe.ml:8000/media/Schulungsleitfaden0.3.pdf')">Selbstschulungsleitfaden (Version 0.3)</a>

            Bestandteile des Leitfadens sind die Grundlagenschulen von KJÖ und SDAJ:
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://kjoe.ml:8000/media/Grundlagenschule.pdf"
                  onclick="window.open('https://kjoe.ml:8000/media/Grundlagenschule.pdf')">KJÖ Grundlagenschule</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.sdaj.org/material/grundlagenschule/"
                  onclick="window.open('https://www.sdaj.org/material/grundlagenschule/')">SDAJ Grundlagenschule</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col mt-0">
              <h5 class="card-title">Liste nützlicher Plattformen mit marxistischen Inhalten</h5>
            </div>

            <div class="col-auto">
              <div class="stat text-primary"><i class="align-middle" data-feather="users" /></div>
            </div>
          </div>

          <div class="mb-0">
            <ul>
              <li><a target="_blank" href="http://www.mlwerke.de/" onclick="window.open('http://www.mlwerke.de/')">ML-Werke</a></li>
              <li>
                <a
                  target="_blank"
                  href="http://www.max-stirner-archiv-leipzig.de/philosophie.html"
                  onclick="window.open('http://www.max-stirner-archiv-leipzig.de/philosophie.html')">Philosophie digital 2.0</a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://www.marxists.org/deutsch/archiv/"
                  onclick="window.open('https://www.marxists.org/deutsch/archiv/')">Marxists Org</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://sites.google.com/site/sozialistischeklassiker2punkt0/"
                  onclick="window.open('https://sites.google.com/site/sozialistischeklassiker2punkt0/')">Sozialistische Klassiker</a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://www.sdaj.org/position-das-magazin/"
                  onclick="window.open('https://www.sdaj.org/position-das-magazin/')">SDAJ Positionen</a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://dkp.de/partei/theorie-und-bildung/"
                  onclick="window.open('https://dkp.de/partei/theorie-und-bildung/')">DKP - Theorie und Bildung</a>
              </li>

              <li>
                <a
                  target="_blank"
                  href="https://wiki.kommunistische.org/index.php?title=Hauptseite"
                  onclick="window.open('https://wiki.kommunistische.org/index.php?title=Hauptseite')">Bolsche.Wiki</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col mt-0">
              <h5 class="card-title">Funktionen dieser Plattform</h5>
            </div>
          </div>
          <div class="row">
            <ul>
              <li><b>Inhalt</b>: Die Plattform beinhaltet den größten Teil der Marxistischen Blätter sowie alle Klassiker (zB Marx, Engels, Lenin)</li>
              <li><b>Selbst Dokumente hochladen</b>: ihr habt Werke als Pdf die euch auf der Plattform fehlen? Bitte ladet diese im Tab "File Hochladen" hoch und fügt unter "EDIT" (unters Menü) Metadaten hinzu</li>
              <li><b>Suche</b>: Der gesamte Fundus kann unter "SUCHE" (unteres Menü) nach einem Stichwort durchsucht werden</li>
              <li><b>Einordnung nach Kategorie</b>: der Tab "Nach Kategorie" soll das Finden von Materialien erleichtern. Hierzu muss jedoch bekannt sein, welche Werke welche Themen beinhalten. Bitte an alle: im Tab "EDIT" ist es möglich diese Informationen zur Verfügung zu stellen. Anleitung: man sucht zum Beispiel im Tab "EDIT" nach Engels suchen - Ursprung der Familie auswählen - und schließlich den Tag "Staat und Revolution" hinzufügen. Vielen Dank für eure Mithilfe! </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col mt-0" />

            <div class="col-auto">
              <div class="stat text-primary"><i class="align-middle" data-feather="dollar-sign" /></div>
            </div>
          </div>

          <h1 class="mt-1 mb-3">123123</h1>
          <div class="mb-0">
            <span class="text-success"> <i class="mdi mdi-arrow-bottom-right" /> 12% </span>

            <span class="text-muted">Since last month</span>
          </div>
        </div>
      </div>
    </div> -->
  </div>
{/if}
